import React from "react"

const currentYear = new Date().getFullYear()

const Footer = () => (
  <footer id="footer">
    <div className="container d-md-flex py-4">
      <div className="col-12 text-center py-4">
        <div className="copyright text-center">
          <p>
            <a href="tel:+918265963621">
              <i className="icofont-mobile-phone" aria-hidden="true"></i>
              +91 82659 63621
            </a>
            <strong> | </strong>
            <a href="mailto:info@d2itechnology.com">
              <i className="icofont-envelope" aria-hidden="true"></i>
              {" "}info@d2itechnology.com
            </a>
          </p>
        </div>
        <div className="copyright text-center">
          <p>
            &copy;{currentYear}{" "}
            <strong>
              <span>D2i Technology</span>
            </strong>
            . All Rights Reserved.
          </p>
        </div>
        <div className="social-links text-center">
          <ul className="list-unstyled inline-list">
            <li className="list-inline-item mb-2">
              <a
                href="https://twitter.com/d2itechnology"
                target="_blank"
                title="Twitter"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="32"
                  viewBox="0 0 50 50"
                  fill="white"
                  aria-hidden="true"
                  focusable="false"
                  style={{ marginTop: "-5px" }}
                >
                  <path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"></path>
                </svg>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.facebook.com/d2itechnology"
                target="_blank"
                title="Facebook"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.instagram.com/d2itechnology/"
                target="_blank"
                title="Instagram"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.linkedin.com/company/d2itechnology"
                target="_blank"
                title="LinkedIn"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-linkedin" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.youtube.com/channel/UCt81Dry8H_uc-35IDIN6biA"
                target="_blank"
                title="YouTube"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-youtube" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.reddit.com/user/d2itechnology"
                target="_blank"
                title="Reddit"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-reddit" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://in.pinterest.com/d2itechnology/"
                target="_blank"
                title="Pinterest"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-pinterest-alt" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item mb-2">
              <a
                href="https://www.quora.com/profile/D2itechnology"
                target="_blank"
                title="Quora"
                rel="noopener noreferrer"
              >
                <i className="bx bxl-quora" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
