// import { AnchorLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from '../assets/img/d2i_logo.png'

const Header = ({siteTitle, hideNav}) => {
  const [navOpen,setNavOpen] = useState(false)
  const [servicesDropdownExpanded,setServicesDropdownExpanded] = useState(false)
  const [testingDropdownExpanded,setTestingDropdownExpanded] = useState(false)
  const [penetrationDropdownExpanded,setPenetrationDropdownExpanded] = useState(false)

  const closeMenu = () => {
    setNavOpen(false)
    setServicesDropdownExpanded(false)
    setTestingDropdownExpanded(false)
  }
  return (
  <header id="header" className={navOpen ? 'fixed-top mobile-nav-active': 'fixed-top'}>
  <div  className={navOpen ? 'mobile-nav-overly d-block': 'mobile-nav-overly'}  onClick={()=> setNavOpen(false)}></div>
  <div className="container d-flex align-items-center">

    <div className="logo mr-auto">
      <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/">
      <img id="logoImage" src={logo} alt="D2i Technology - Software development company logo" />
        <div className="d-sm-inline mt-1 ">{siteTitle}</div>
      </AnchorLink>
    </div>  
    {!hideNav && (<>
    <nav className="nav-menu d-none d-lg-block">
      <ul>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/">Home</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/blogs/">Blogs</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#about">About</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#portfolio">Our Products</AnchorLink></li>

        <li className="drop-down"><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services">Services</AnchorLink>
          <ul>
       
            <li className="drop-down"><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services">Testing</AnchorLink>
              <ul>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/manual-testing/">Manual Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/automation-testing/">Automation Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/performance-testing/">Performance Testing</AnchorLink></li>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-testing/">Accessibility Testing</AnchorLink></li>           
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/security-testing/">Security Testing</AnchorLink></li>           

                <li className="drop-down"><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/penetration-testing/">Penetration Testing</AnchorLink>
                <ul>
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/android-penetration-testing/">Android Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/api-penetration-testing/">API Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/cloud-penetration-testing/">Cloud Penetration Testing </AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/network-penetration-testing/">Network Penetration Testing </AnchorLink></li> 

              </ul></li>
              </ul> </li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/website-development/">Web Development</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/mobile-development/">Mobile Development</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-remediation/">Accessibility Remediation</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/devops/">DevOps</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/secops/">SecOps</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/digital-marketing/">Digital Marketing</AnchorLink></li>
            <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/ai-development/">AI Development</AnchorLink></li>
          </ul>
        </li>


        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#clients">Our Clients</AnchorLink></li>
     
       
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams">Our Team</AnchorLink></li> */}
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/careers">Careers</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams/user"></AnchorLink></li> */}

      </ul>
    </nav>
    <nav className="mobile-nav d-lg-none">
      {/* Div added to provide scroll option in hamburger menu for easy navigation.*/}
    <div style={{ maxHeight: "calc(100vh - 60px)", overflowY: "auto" }}>
      <ul>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/"  onAnchorLinkClick={()=>closeMenu()}>Home</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/blogs/"  onAnchorLinkClick={()=>closeMenu()}>Blogs</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#about"  onAnchorLinkClick={()=>closeMenu()}>About</AnchorLink></li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#portfolio"  stripHash={true} onAnchorLinkClick={()=>closeMenu()}>Our Products</AnchorLink></li>
     
        <li className={servicesDropdownExpanded ? 'active drop-down': 'drop-down'}><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services"  onAnchorLinkClick={()=>closeMenu()}>Services </AnchorLink> <span className="toggle" onClick={()=> setServicesDropdownExpanded(!servicesDropdownExpanded)}></span>
        {servicesDropdownExpanded && (
              <ul>
       
              <li className={testingDropdownExpanded ? 'active drop-down': 'drop-down'}><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#services"  onAnchorLinkClick={()=>closeMenu()}>Testing </AnchorLink> <span className="toggle" onClick={()=> setTestingDropdownExpanded(!testingDropdownExpanded)}></span>
               {testingDropdownExpanded && (
                  <ul>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/manual-testing/" onAnchorLinkClick={()=>closeMenu()}>Manual Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/automation-testing/"  onAnchorLinkClick={()=>closeMenu()}>Automation Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/performance-testing/"  onAnchorLinkClick={()=>closeMenu()}>Performance Testing</AnchorLink></li>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-testing/"  onAnchorLinkClick={()=>closeMenu()}>Accessibility Testing</AnchorLink></li>           
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/security-testing/"  onAnchorLinkClick={()=>closeMenu()}>Security Testing</AnchorLink></li>           
                  <li  className={penetrationDropdownExpanded ? 'active drop-down': 'drop-down'}><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/penetration-testing/"    onAnchorLinkClick={()=>closeMenu()}>Penetration Testing</AnchorLink> <span className="toggle" onClick={()=> setPenetrationDropdownExpanded(!penetrationDropdownExpanded)}></span>
        {penetrationDropdownExpanded && (
                  <ul>
                  <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/android-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Android Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/api-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>API Penetration Testing</AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/cloud-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Cloud Penetration Testing </AnchorLink></li> 
                <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/network-penetration-testing/"  onAnchorLinkClick={()=>closeMenu()}>Network Penetration Testing </AnchorLink></li> 
                </ul>)}</li>
                </ul>
               )}
                </li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/website-development/"  onAnchorLinkClick={()=>closeMenu()}>Web Development</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/mobile-development/"  onAnchorLinkClick={()=>closeMenu()}>Mobile Development</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/accessibility-remediation/"  onAnchorLinkClick={()=>closeMenu()}>Accessibility Remediation</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/devops/"  onAnchorLinkClick={()=>closeMenu()}>DevOps</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/secops/"  onAnchorLinkClick={()=>closeMenu()}>SecOps</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/digital-marketing/"  onAnchorLinkClick={()=>closeMenu()}>Digital Marketing</AnchorLink></li>
              <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/ai-development/"  onAnchorLinkClick={()=>closeMenu()}>AI Development</AnchorLink></li>
            </ul>
        )}
        </li>
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/#clients"   onAnchorLinkClick={()=>closeMenu()}>Our Clients</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams"  onAnchorLinkClick={()=>closeMenu()}>Our Team</AnchorLink></li> */}
        <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/careers"  onAnchorLinkClick={()=>closeMenu()}>Careers</AnchorLink></li>
        {/* <li><AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/teams/user"  onAnchorLinkClick={()=>closeMenu()}></AnchorLink></li> */}

      </ul>
      </div>
    </nav>
    
    <AnchorLink gatsbyLinkProps={{activeClassName: "active"}}  to="/contact" className="get-started-btn scrollto">Contact Us</AnchorLink>
    <button type="button" className="mobile-nav-toggle d-lg-none" onClick={()=> setNavOpen(!navOpen)} aria-label="Open Navigation"><i className={navOpen ? 'icofont-close': 'icofont-navigation-menu '} aria-hidden="true"></i></button>
    </>)}
  </div>
</header>
)
}
 
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
